<template>
  <v-app>
    <div v-if="getLoadingStatus" id="loading">
      <!-- <div class="loading-img"></div> -->
      <img
        class="loading-img"
        src="../src/assets/images/loadingInfo.png"
        alt=""
      />
    </div>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "App",

  data: () => ({
    //
  }),

  computed: {
    ...mapGetters(["getLoadingStatus"]),
  },
};
</script>

<style>
@import "https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500&display=swap";

@import "./assets/css/style.css";
@import "./assets/css/loading.css";

#app {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
}
</style>
