import {
  requestAdminLogin,
  requestGetObituaryList,
  requestGetAdminUserList,
  requestRegistAdminUser,
  requestDeleteAdminUser,
  requestResetAdminUserPwd,
} from "../apis/admin_api";

const state = {
  admin: null,
  isAdmin: false,
  funeralList: [],

  eventObituaryList: [],
  obituaryTotalPages: 0,
  obituaryPageSize: 10,
  totalAliveCount: 0,
  totalEndCount: 0,

  adminUserList: [],
  adminUserTotalPages: 0,
  adminUserPageSize: 10,
};
const getters = {
  isAuthenticated: (state) => !!state.token,
  isAdmin: (state) => state.isAdmin,

  getAdmin: (state) => state.admin,
  getAllFuneralList: (state) => state.funeralList,

  getObituaryList: (state) => state.eventObituaryList,
  getObituaryTotalPages: (state) => state.obituaryTotalPages,
  getObituaryPageSize: (state) => state.obituaryPageSize,

  getTotalAliveCount: (state) => state.totalAliveCount,
  getTotalEndCount: (state) => state.totalEndCount,

  getAdminUserList: (state) => state.adminUserList,
  getAdminUserTotalPages: (state) => state.adminUserTotalPages,
  getAdminUserPageSize: (state) => state.adminUserPageSize,
};
const mutations = {
  SET_ADMIN(state, payload) {
    if (!payload) {
      state.admin = null;
      return;
    }

    state.admin = payload.admin;
    state.isAdmin = payload?.admin?.isAdmin;

    state.funeralList = payload?.funeralList;
  },

  SET_OBITUARY_LIST(state, payload) {
    state.eventObituaryList = payload.eventObituaryList;
    state.obituaryTotalPages = payload.totalPages;
    state.obituaryPageSize = payload.pageSize;

    state.totalAliveCount = payload.totalAliveCount;
    state.totalEndCount = payload.totalEndCount;
  },

  SET_ADMIN_USER_LIST(state, payload) {
    state.adminUserList = payload.adminUserList;
    state.adminUserTotalPages = payload.totalPages;
    state.adminUserPageSize = payload.pageSize;
  },
};
const actions = {
  async REQUEST_ADMIN_LOGIN({ commit }, params) {
    try {
      const data = await requestAdminLogin(params);

      if (data?.result) {
        localStorage.setItem("v4_token", data.result.token);
        commit("SET_ADMIN", data.result);

        return true;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  // 관리자 페이지 부고 불러오기
  async REQUEST_GET_OBITUARY_LIST({ commit }, params) {
    try {
      const data = await requestGetObituaryList(params);

      if (data?.result) {
        commit("SET_OBITUARY_LIST", data.result);

        return true;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  // 관리자 페이지 사용자 리스트 불러오기
  async REQUEST_GET_ADMIN_USER_LIST({ commit }, params) {
    try {
      const data = await requestGetAdminUserList(params);

      if (data?.result) {
        commit("SET_ADMIN_USER_LIST", data.result);

        return true;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  async REQUEST_REGIST_ADMIN_USER(_, params) {
    try {
      const data = await requestRegistAdminUser(params);

      if (data?.result) {
        return true;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  async REQUEST_DELETE_ADMIN_USER(_, params) {
    try {
      const data = await requestDeleteAdminUser(params);

      if (data?.result) {
        return true;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  async REQUEST_RESET_ADMIN_USER_PWD(_, params) {
    try {
      const data = await requestResetAdminUserPwd(params);

      if (data?.result) {
        return true;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
};

export default { mutations, state, actions, getters };
