import {
  requestEvent,
  requestEventUpdate,
  requestSendBugoSender,
  requestSendExTalkBugo,
  requestUserSave,
  requestUserDelete,
  requestBankCertify,
  requestUserBankDelete,
  requestSendMessage,
  requestSendMessageUsers,
  requestSendMessageList,
  requestReserveMessageUsers,
  requestReseveMessageList,
  requestCancelReseveMessage,
  requestObituaryPrint,
} from "../apis/service_api";

const state = {};
const getters = {};
const mutations = {};
const actions = {
  // 행사 정보 조회
  async REQUEST_EVENT(_, event_no) {
    try {
      const result = await requestEvent(event_no);

      if (result) {
        return result;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  // 행사 관련 수정
  async REQUEST_EVENT_UPDATE(_, params) {
    try {
      const result = await requestEventUpdate(params);

      if (result) {
        return result;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  // 부고장 페이지 전송
  async REQUEST_SEND_BUGO_SENDER(_, params) {
    try {
      const result = await requestSendBugoSender(params);

      if (result) {
        return result;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  // 상주 외 부고 전송
  async REQUEST_SEND_EX_TALK_BUGO(_, params) {
    try {
      const result = await requestSendExTalkBugo(params);

      if (result) {
        return result;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  // 상주 저장
  async REQUEST_USER_SAVE(_, params) {
    try {
      const result = await requestUserSave(params);

      if (result) {
        return result;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  // 상주 삭제
  async REQUEST_USER_DELETE(_, params) {
    try {
      const result = await requestUserDelete(params);

      if (result) {
        return result;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  // 조의금 계좌 인증
  async REQUEST_BANK_CERTIFY(_, params) {
    try {
      const result = await requestBankCertify(params);

      if (result) {
        return result;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  async REQUEST_USER_BANK_DELETE(_, params) {
    try {
      const result = await requestUserBankDelete(params);

      if (result) {
        return result;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  // 각종 메시지 (알림톡, 문자, 답례글 ) 보내기
  async REQUEST_SEND_MESSAGE(_, params) {
    try {
      const result = await requestSendMessage(params);

      if (result) {
        return result;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  // 상주 일괄전송 & 선택 전송
  async REQUEST_SEND_MESSAGE_USERS(_, params) {
    try {
      const result = await requestSendMessageUsers(params);

      if (result) {
        return result;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  // 메시지 전송 내역 불러오기
  async REQUEST_SEND_MSG_LIST(_, event_no) {
    try {
      const result = await requestSendMessageList(event_no);

      if (result) {
        return result;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  // 상주 예약 전송
  async REQUEST_RESERVE_MESSAGE_USERS(_, params) {
    try {
      const result = await requestReserveMessageUsers(params);

      if (result) {
        return result;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  // 메시지 예약 내역 불러오기
  async REQUEST_RESERVE_MSG_LIST(_, event_no) {
    try {
      const result = await requestReseveMessageList(event_no);

      if (result) {
        return result;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  // 예약 삭제
  async REQUEST_CANCEL_RESERVE_MESSAGE(_, params) {
    try {
      const result = await requestCancelReseveMessage(params);

      if (result) {
        return result;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  //프린트 정보 요청
  async REQUEST_OBITUARY_PRINT(_, params) {
    try {
      const result = await requestObituaryPrint(params);

      if (result) {
        return result;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
};

export default { mutations, state, actions, getters };
