import {
  requestGetFuneralCalList,
  requestGetFuneralCalDetail,
} from "../apis/cal_api";

const state = {
  // 총 화환 통계
  totalFuneralCalInfo: {
    totalOrderCount: 0,
    totalOrderAmount: 0,
    totalPaybackAmount: 0,
    totalMangedAmount: 0,
  },

  funeralCalList: [],
  funeralCalTotalPages: 0,
  funeralCalPageSize: 10,

  // 업체당 주문 통계
  totalFuneralCalDetailInfo: {
    funeralName: "",
    totalOrderCount: 0,
    totalOrderAmount: 0,
    totalPaybackAmount: 0,
    totalMangedAmount: 0,
  },

  funeralCalDetailList: [],
  funeralCalDetailTotalPages: 0,
  funeralCalDetailPageSize: 10,
};
const getters = {
  getFuneralCalList: (state) => state.funeralCalList,
  getFuneralCalTotalPages: (state) => state.funeralCalTotalPages,
  getFuneralCalPageSize: (state) => state.funeralCalPageSize,
  getTotalFuneralCalInfo: (state) => state.totalFuneralCalInfo,

  getFuneralCalDetailList: (state) => state.funeralCalDetailList,
  getFuneralCalDetailTotalPages: (state) => state.funeralCalDetailTotalPages,
  getFuneralCalDetailPageSize: (state) => state.funeralCalDetailPageSize,
  getTotalFuneralCalDetailInfo: (state) => state.totalFuneralCalDetailInfo,
};

const mutations = {
  SET_FUNERAL_CAL_LIST(state, payload) {
    state.funeralCalList = payload.funeralCalList.map((funeral, idx) => ({
      ...funeral,
      index: idx + 1,
      orderCount: funeral.FuneralCals?.length, // 결제 건수
      cancellledOrderCount: funeral.FuneralCals?.filter(
        (v) => v.order_status === "cancelled"
      ).length, // 결제 건수
      orderAmount: funeral.FuneralCals?.reduce(
        (accumulator, cal) => accumulator + parseInt(cal.ShopOrder.shop_price),
        0
      ), // 총 결제 금액

      realOrderAmount: funeral.FuneralCals?.filter(
        (v) => v.order_status === "paid"
      )?.reduce(
        (accumulator, cal) => accumulator + parseInt(cal.ShopOrder.shop_price),
        0
      ), // 총 순 거래액,
      cancelledOrderAmount: funeral.FuneralCals?.filter(
        (v) => v.order_status === "cancelled"
      )?.reduce(
        (accumulator, cal) => accumulator + parseInt(cal.ShopOrder.shop_price),
        0
      ), // 총 취소액

      orderPaybackRate: funeral.FuneralCals[0].payback_a_amount / 100, // 해당 식장 지원률
      orderPaybackAmount: funeral.FuneralCals?.filter(
        (item) => item.order_status === "paid"
      )?.reduce(
        (accumulator, cal) => accumulator + parseInt(cal.cal_a_amount),
        0
      ), // 총 지원 금액
      managedCount: funeral.FuneralCals?.filter((cal) => cal.managed_flag)
        .length, // 관리비 건 수
      managedExclusionCount: funeral.FuneralCals?.filter(
        (cal) => !cal.managed_flag && cal.order_status === "paid"
      ).length, // 관리비 바구니 건 수
      mangedAmount: funeral.FuneralCals?.reduce(
        (accumulator, cal) => accumulator + parseInt(cal.managed_cost),
        0
      ), // 총 관리비용
    }));
    state.funeralCalTotalPages = payload.totalPages;
    state.funeralCalPageSize = payload.pageSize;
    state.totalFuneralCalInfo = payload.totalFuneralCalInfo;
  },

  SET_FUNERAL_CAL_DETAIL(state, payload) {
    state.funeralCalDetailList = payload.funeralCalDetailList;
    state.funeralCalDetailTotalPages = payload.totalPages;
    state.funeralCalDetailPageSize = payload.pageSize;
    state.totalFuneralCalDetailInfo = payload.totalFuneralCalDetailInfo;
  },
};

const actions = {
  // 총 화환 통계 불러오기
  async REQUEST_GET_FUNERAL_CAL_LIST({ commit }, params) {
    try {
      const data = await requestGetFuneralCalList(params);

      if (data?.result) {
        commit("SET_FUNERAL_CAL_LIST", data.result);

        return true;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
  async REQUEST_GET_FUNERAL_CAL_DETAIL({ commit }, params) {
    try {
      const data = await requestGetFuneralCalDetail(params);

      if (data?.result) {
        commit("SET_FUNERAL_CAL_DETAIL", data.result);

        return true;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  // 업체 내 화환 주문 건 수 불러오기
  // async REQUEST_COMPANY_WREATH_ORDER_HISTORY({ commit }, payload) {
  //   try {
  //     const res = await requestCompanyWreathOrderHistory(payload);

  //     const {
  //       data: { result, resultFuneral, resultOrderList, page, pageTotal },
  //     } = res;

  //     if (result !== "success") {
  //       return;
  //     }

  //     console.log(resultOrderList, pageTotal);

  //     const totalOrderCount = resultOrderList?.length;

  //     const totalOrderAmount = resultOrderList?.reduce(
  //       (accumulator, obj) => accumulator + parseInt(obj.price),
  //       0
  //     );

  //     const totalPaybackAmount = Math.floor(
  //       totalOrderAmount *
  //         (resultFuneral.name === "여수경산장례식장" ? 0.3 : 0.2)
  //     );

  //     const managedCount = resultOrderList.filter(
  //       (v) => v.name !== "영정바구니" && v.name !== "영정바구니 고급"
  //     )?.length;

  //     const totalMangedAmount = managedCount * 10000;

  //     coDetailnst uneralCalInfo = {
  //       funeralName: resultFuneral.name,
  //       totalOrderCount,
  //       totalOrderAmount,
  //       totalPaybackAmount,
  //       totalMangedAmount,
  //     };

  //     commit("SET_COMPANY_ORDER_HISTORY", {
  //       companyOrderHistoryList: resultOrderList,
  //       DetailuneralCalInfo,
  //       page,
  //       pageTotal,
  //     });
  //   } catch (e) {
  //     if (e === "refresh") {
  //       return;
  //     }

  //     console.error(e);
  //     alert(
  //       "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
  //     );
  //   } finally {
  //     commit("END_LOADING");
  //   }
  // },
};

export default { mutations, state, actions, getters };
