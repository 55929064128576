import axios from "axios";

const client = axios.create({
  baseURL: `${process.env.VUE_APP_APIURL}/city`,
});

client.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("v4_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const requestGetCityList = async (params) => {
  return client
    .post(`/list`, params)
    .then((res) => {
      const { data } = res;

      return data;
    })
    .catch((err) => {
      alert("도시 조회에 실패하였습니다");
      console.error(err);
    });
};

const requestGetCityFuneralList = async (params) => {
  return client
    .post(`/funeral/list`, params)
    .then((res) => {
      const { data } = res;

      return data;
    })
    .catch((err) => {
      alert("도시 내 장례식장 조회에 실패하였습니다");
      console.error(err);
    });
};

// 배송비 수정
const requestSaveCityDeliCost = async (params) => {
  return client
    .post(`/save/deli`, params)
    .then((res) => {
      const { data } = res;

      return data;
    })
    .catch((err) => {
      alert("도시 배송비 수정에 실패하였습니다");
      console.error(err);
    });
};

//특정 장례식장 배송비 수정
const requestSaveFuneralDeliCost = async (params) => {
  return client
    .post(`/funeral/save/deli`, params)
    .then((res) => {
      const { data } = res;

      return data;
    })
    .catch((err) => {
      alert("장례식장 배송비 수정에 실패하였습니다");
      console.error(err);
    });
};

export {
  requestGetCityList,
  requestGetCityFuneralList,
  requestSaveCityDeliCost,
  requestSaveFuneralDeliCost,
};
