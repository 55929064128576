import axios from "axios";

const client = axios.create({
  baseURL: `${process.env.VUE_APP_APIURL}/jrbaksa-service/tablet/surName`,
});

const requestSurNameList = async (params) => {
  return client
    .get(`/list?keyword=${encodeURIComponent(params.keyword)}`)
    .then((res) => {
      const { data } = res;
      if (data.code === "E001") {
        alert("본관 검색에 실패하였습니다");

        return;
      }
      return data;
    })
    .catch((err) => {
      alert("본관 검색에 실패하였습니다");
      console.error(err);
    });
};

export { requestSurNameList };
