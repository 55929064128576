import axios from "axios";

const client = axios.create({
  baseURL: `${process.env.VUE_APP_APIURL}/jrbaksa-service`,
});

const requestEvent = async (event_no) => {
  return client.get(`?event_no=${event_no}`).then((res) => {
    const { data } = res;

    if (data.code === "0000") {
      return data;
    }

    alert(data.message);

    return null;
  });
};

const requestEventUpdate = async (params) => {
  return client.post(`/${params.target}/update`, params).then((res) => {
    const { data } = res;

    if (data.code === "0000") {
      return data;
    }

    return null;
  });
};

const requestSendBugoSender = async (params) => {
  return client
    .post(`/bugo-sender`, params)
    .then((res) => {
      const { data } = res;

      if (data.code === "0000") {
        return data;
      }

      alert("부고장 페이지 전송을 실패하였습니다");
      return null;
    })
    .catch((err) => {
      alert("부고장 페이지 전송을 실패하였습니다");
      console.error(err);
    });
};

const requestSendExTalkBugo = async (params) => {
  return client
    .post(`/send/bugo-ex-user`, params)
    .then((res) => {
      const { data } = res;

      if (data.code === "0000") {
        return data;
      }

      return null;
    })
    .catch((err) => {
      alert("부고장 페이지 전송을 실패하였습니다");
      console.error(err);
    });
};

//상주 저장
const requestUserSave = async (params) => {
  return client
    .post(`/update/user`, params)
    .then((res) => {
      const { data } = res;

      if (data.code === "0000") {
        return data;
      }

      alert("상주 저장을 실패하였습니다");
      return null;
    })
    .catch((err) => {
      alert("상주 저장을 실패하였습니다");
      console.error(err);
    });
};

//상주 삭제
const requestUserDelete = async (params) => {
  return client
    .post(`/delete/user`, params)
    .then((res) => {
      const { data } = res;

      if (data.code === "0000") {
        return data;
      }

      return null;
    })
    .catch((err) => {
      alert("상주 삭제를 실패하였습니다");
      console.error(err);
    });
};

//조의금 계좌 받기
const requestBankCertify = async (params) => {
  return client
    .post(`/bank/certify`, params)
    .then((res) => {
      const { data } = res;

      return data;
    })
    .catch((err) => {
      alert("계좌 인증을 실패하였습니다");
      console.error(err);
    });
};

const requestUserBankDelete = async (params) => {
  return client
    .post(`/bank/delete`, params)
    .then((res) => {
      const { data } = res;

      return data;
    })
    .catch((err) => {
      alert("계좌 삭제를 실패하였습니다");
      console.error(err);
    });
};

// 각종 메시지 (알림톡, 문자, 답례글 ) 보내기
const requestSendMessage = async (params) => {
  return client
    .post(`/send/message`, params)
    .then((res) => {
      const { data } = res;

      if (data.code === "0000") {
        return data;
      }

      alert("부고 전송을 실패하였습니다");
      return null;
    })
    .catch((err) => {
      alert("부고 전송을 실패하였습니다");
      console.error(err);
    });
};

const requestSendMessageUsers = async (params) => {
  return client
    .post(`/send/message/users`, params)
    .then((res) => {
      const { data } = res;

      return data;
    })
    .catch((err) => {
      alert("부고 전송을 실패하였습니다");
      console.error(err);
    });
};

const requestSendMessageList = async (event_no) => {
  return client
    .get(`/send/message-list?event_no=${event_no}`)
    .then((res) => {
      const { data } = res;

      return data;
    })
    .catch((err) => {
      alert("전송 메시지 내역 조회에 실패하였습니다");
      console.error(err);
    });
};

const requestReserveMessageUsers = async (params) => {
  return client
    .post(`/reserve/message/users`, params)
    .then((res) => {
      const { data } = res;

      return data;
    })
    .catch((err) => {
      alert("부고 예약을 실패하였습니다");
      console.error(err);
    });
};

const requestReseveMessageList = async (event_no) => {
  return client
    .get(`/reserve/message-list?event_no=${event_no}`)
    .then((res) => {
      const { data } = res;

      return data;
    })
    .catch((err) => {
      alert("예약 메시지 내역 조회에 실패하였습니다");
      console.error(err);
    });
};

const requestCancelReseveMessage = async (params) => {
  return client
    .post(`/reserve/cancel-message`, params)
    .then((res) => {
      const { data } = res;

      if (data.code === "0000") {
        return data;
      }

      alert(data.message);
      return null;
    })
    .catch((err) => {
      alert("예약 메시지 취소에 실패하였습니다");
      console.error(err);
    });
};

const requestObituaryPrint = async (params) => {
  return client
    .get(`/print/${params.event_no}`)
    .then((res) => {
      const { data } = res;

      if (data.code === "0000") {
        return data.result;
      }

      alert(data.message);
      return null;
    })
    .catch((err) => {
      alert("부고 출력에 실패하였습니다");
      console.error(err);
    });
};

export {
  requestEvent,
  requestEventUpdate,
  requestSendBugoSender,
  requestSendExTalkBugo,
  requestUserSave,
  requestUserDelete,
  requestBankCertify,
  requestUserBankDelete,
  requestSendMessage,
  requestSendMessageUsers,
  requestSendMessageList,
  requestReserveMessageUsers,
  requestReseveMessageList,
  requestCancelReseveMessage,
  requestObituaryPrint,
};
