import Vue from "vue";
import Vuex from "vuex";

import loader from "./loader";

import service from "./service";
import obituary from "./obituary";
import admin from "./admin";
import funeral from "./funeral";
import cal from "./cal";
import city from "./city";
import shop from "./shop";
import shopOrder from "./shopOrder";

import surName from "./surName";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    loader,
    service,
    obituary,
    admin,
    funeral,
    cal,
    city,
    shop,
    shopOrder,
    surName,
  },
  state: {},
  mutations: {},
  actions: {},
});
