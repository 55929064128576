import axios from "axios";

const client = axios.create({
  baseURL: `${process.env.VUE_APP_APIURL}/obituary`,
});

// 부고 불러오기
const requestGetObituary = async (params) => {
  return client
    .get(`/?event_no=${params.event_no}&seq_no=${params.seq_no}`)
    .then((res) => {
      const { data } = res;

      return data.result;
    })
    .catch((err) => {
      alert("부고 조회를 실패하였습니다");
      console.error(err);
    });
};

const requestGetObituaryUsers = async (params) => {
  return client
    .get(`/users?event_no=${params.event_no}`)
    .then((res) => {
      const { data } = res;

      return data.result;
    })
    .catch((err) => {
      alert("부고 조회를 실패하였습니다");
      console.error(err);
    });
};

const requestGetObituarySetting = async (params) => {
  return client
    .get(`/setting?seq_no=${params.seq_no}`)
    .then((res) => {
      const { data } = res;

      return data.result;
    })
    .catch((err) => {
      alert("부고 조회를 실패하였습니다");
      console.error(err);
    });
};

const requestGetObituaryReply = async (params) => {
  return client
    .get(`/reply?seq_no=${params.seq_no}`)
    .then((res) => {
      const { data } = res;

      return data.result;
    })
    .catch((err) => {
      alert("부고 조회를 실패하였습니다");
      console.error(err);
    });
};

// 부고 수정 혹은 저장
const requestSaveObituary = async (params) => {
  let form = new FormData();
  for (let i = 0; i < params.files.length; i++) {
    form.append("fileList", params.files[i]);
  }

  form.append("obituary", JSON.stringify(params.obituary));
  form.append("obituaryOption", JSON.stringify(params.obituaryOption));
  form.append("eventFamilies", JSON.stringify(params.eventFamilies));
  form.append("register", JSON.stringify(params.register));

  const token = localStorage.getItem("v4_token");
  return client
    .post(`/save/obituary`, form, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      const { data } = res;

      return data;
    })
    .catch((err) => {
      alert("부고 저장을 실패하였습니다");
      console.error(err);
    });
};

// 부고 발인상태 변경
const requestUpdateObituaryAlive = async (params) => {
  const token = localStorage.getItem("v4_token");

  return client
    .post(`/update/alive`, params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      const { data } = res;

      return data;
    })
    .catch((err) => {
      alert("발인 상태 변경을 실패하였습니다");
      console.error(err);
    });
};
// 부고삭제
const requestDeleteObituary = async (params) => {
  const token = localStorage.getItem("v4_token");

  return client
    .post(`/delete`, params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      const { data } = res;

      return data;
    })
    .catch((err) => {
      alert("부고 삭제를 실패하였습니다");
      console.error(err);
    });
};
// 추모 메시지 저장 및 수정
const requestUpdateObituaryComment = async (params) => {
  return client
    .post(`/update/obituaryComment`, params)
    .then((res) => {
      const { data } = res;

      return data.result;
    })
    .catch((err) => {
      alert("추모 메시지 저장을 실패하였습니다");
      console.error(err);
    });
};

// 추모 메시지 삭제
const requestDeleteObituaryComment = async (params) => {
  return client
    .post(`/delete/obituaryComment`, params)
    .then((res) => {
      const { data } = res;

      return data.result;
    })
    .catch((err) => {
      alert("추모 메시지 저장을 실패하였습니다");
      console.error(err);
    });
};

// 추모 메시지 비밀번호 확인
const requestObituaryCommentPwdCheck = async (params) => {
  return client
    .post(`/check-pwd/obituaryComment`, params)
    .then((res) => {
      const {
        data: { code, message, result },
      } = res;

      if (code === "E001") {
        alert(message);
        return;
      }

      return result;
    })
    .catch((err) => {
      alert("네트워크 문제로 실패하였습니다. 다시 시도하여 주십시요");
      console.error(err);
    });
};

// 개인정보 동의
const requestObituarAgree = async (params) => {
  return client
    .post(`/agree`, params)
    .then((res) => {
      const {
        data: { code, message, result },
      } = res;

      if (code === "E001") {
        alert(message);
        return;
      }

      return result;
    })
    .catch((err) => {
      alert("네트워크 문제로 실패하였습니다. 다시 시도하여 주십시요");
      console.error(err);
    });
};
export {
  requestGetObituary,
  requestGetObituaryUsers,
  requestGetObituarySetting,
  requestGetObituaryReply,
  requestSaveObituary,
  requestUpdateObituaryAlive,
  requestDeleteObituary,
  requestUpdateObituaryComment,
  requestDeleteObituaryComment,
  requestObituaryCommentPwdCheck,
  requestObituarAgree,
};
