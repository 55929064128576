export default [
  {
    meta: {
      title: "Service",
    },
    path: "/service",
    name: "Service",
    component: () => import("@/views/service/Service.vue"),
  },
  {
    meta: {
      title: "ServicePrint",
    },
    path: "/service/print",
    name: "ServicePrint",
    component: () => import("@/views/service/Print.vue"),
  },
];
