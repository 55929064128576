export default [
  {
    meta: {
      title: "Obituary",
    },
    path: "/obituary",
    name: "Obituary",
    component: () => import("@/views/obituary/Obituary.vue"),
  },
  {
    meta: {
      title: "ObituarySetting",
    },
    path: "/obituary/setting",
    name: "ObituarySetting",
    component: () => import("@/views/obituary/ObituarySetting.vue"),
  },
  {
    meta: {
      title: "ObituarySender",
    },
    path: "/obituary/sender",
    name: "ObituarySender",
    component: () => import("@/views/obituary/ObituarySender.vue"),
  },

  {
    meta: {
      title: "ObituaryShop",
    },
    path: "/obituary/shop",
    name: "ObituaryShop",
    component: () => import("@/views/obituary/obituaryShop/ObituaryShop.vue"),
  },
  {
    meta: {
      title: "ObituaryShopDetail",
    },
    path: "/obituary/shopDetail",
    name: "ObituaryShopDetail",
    component: () =>
      import("@/views/obituary/obituaryShop/ObituaryShopDetail.vue"),
  },
  {
    meta: {
      title: "ObituaryShopOrder",
    },
    path: "/obituary/shopOrder",
    name: "ObituaryShopOrder",
    component: () =>
      import("@/views/obituary/obituaryShop/ObituaryShopOrder.vue"),
  },
  {
    meta: {
      title: "ObituaryShopComplete",
    },
    path: "/obituary/shopComplete",
    name: "ObituaryShopComplete",
    component: () =>
      import("@/views/obituary/obituaryShop/ObituaryShopComplete.vue"),
  },

  {
    meta: {
      title: "ObituaryReply",
    },
    path: "/obituary/reply",
    name: "ObituaryReply",
    component: () => import("@/views/obituary/obituaryReply/ObituaryReply.vue"),
  },
];
