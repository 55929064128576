import axios from "axios";

const client = axios.create({
  baseURL: `${process.env.VUE_APP_APIURL}/shop/order`,
});

client.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("v4_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const requestGetShopOrderList = async (params) => {
  return client
    .post(`/list`, params)
    .then((res) => {
      const { data } = res;

      return data;
    })
    .catch((err) => {
      alert("주문 목록 조회에 실패하였습니다");
      console.error(err);
    });
};

const requestShopOrderRegist = async (params) => {
  return client
    .post(`/save`, params)
    .then((res) => {
      const { data } = res;

      if (data.code === "0001") {
        alert(data.message);
        return data;
      }
      return data;
    })
    .catch((err) => {
      alert("결제 정보 등록에 실패하였습니다. 다시 시도 해주세요");
      console.error(err);
    });
};

const requestShopOrderCancel = async (params) => {
  return client
    .post(`/cancel`, params)
    .then((res) => {
      const { data } = res;

      if (data.code === "0001") {
        alert(data.message);
        return null;
      }

      return data;
    })
    .catch((err) => {
      alert("결제 취소에 실패하였습니다. 다시 시도 해주세요");
      console.error(err);
    });
};

const requestShopOrderEvent = async (params) => {
  return client
    .get(`/event?EVENT_NO=${params.EVENT_NO}`)
    .then((res) => {
      const { data } = res;

      if (data.code === "0001") {
        alert(data.message);
        return null;
      }

      return data;
    })
    .catch((err) => {
      alert("고인명 확인에 실패하였습니다");
      console.error(err);
    });
};

const requestShopOrderConfirm = async (params) => {
  return client
    .post(`/confirm`, params)
    .then((res) => {
      const { data } = res;

      if (data.code === "0001") {
        alert(data.message);
        return null;
      }

      return data;
    })
    .catch((err) => {
      alert("주문 확인에 실패하였습니다. 다시 시도 해주세요");
      console.error(err);
    });
};

const requestShopDeliConfirm = async (params) => {
  let form = new FormData();
  for (let i = 0; i < params.files.length; i++) {
    form.append("fileList", params.files[i]);
  }

  form.append("orderInfo", JSON.stringify(params.orderInfo));
  form.append("deliInfo", JSON.stringify(params.deliInfo));
  const token = localStorage.getItem("v4_token");

  return client
    .post(`/deli-confirm`, form, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      const { data } = res;

      if (data.code === "0001") {
        alert(data.message);
        return null;
      }

      return data;
    })
    .catch((err) => {
      alert("배송 확인에 실패하였습니다. 다시 시도 해주세요");
      console.error(err);
    });
};

const requestShopMemoUpdate = async (params) => {
  return client
    .post(`/update-memo`, params)
    .then((res) => {
      const { data } = res;

      if (data.code === "0001") {
        alert(data.message);
        return null;
      }

      return data;
    })
    .catch((err) => {
      alert("리본명 수정에 실패하였습니다. 다시 시도 해주세요");
      console.error(err);
    });
};

export {
  requestGetShopOrderList,
  requestShopOrderRegist,
  requestShopOrderCancel,
  requestShopOrderEvent,
  requestShopOrderConfirm,
  requestShopDeliConfirm,
  requestShopMemoUpdate,
};
