import {
  requestGetFuneralList,
  requestGetPaybackSettings,
  requestSaveFuneralPayback,
  requestGetFuneralShopList,
  requestSaveFuneralShop,
  requestSaveFuneralInfo,
  requestFuneralCustomFileUpload,
  requestFuneralCustomFileDelete,
  requestUpdateFuneralRaspberry,
  requestDeleteFuneralRaspberry,
  requestFuneralRaspFileUpload,
  requestFuneralRaspFileDelete,
} from "../apis/funeral_api";

const state = {
  funeralList: [],

  totalPages: 0,
  currentPage: 1,
  pageSize: 10,

  funeralShopList: [],
};
const getters = {
  getFuneralList: (state) => state.funeralList,
  getFuneralTotalPages: (state) => state.totalPages,
  getFuneralPageSize: (state) => state.pageSize,
  getFuneralShopList: (state) => state.funeralShopList,
};
const mutations = {
  SET_FUNERAL_LIST(state, payload) {
    state.funeralList = payload.funeralList;
    state.totalPages = payload.totalPages;
  },

  SET_FUNERAL_SHOP_LIST(state, payload) {
    state.funeralShopList = payload.funeralShopList;
  },
};
const actions = {
  // 장례식장 데이터 불러오기
  async REQUEST_GET_FUNERAL_LIST({ commit }, params) {
    try {
      const data = await requestGetFuneralList(params);

      if (data?.result) {
        commit("SET_FUNERAL_LIST", data.result);

        return true;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  // 해당 장례식장의 페이백 설정 내역 불러오기
  async REQUEST_GET_PAYBACK_SETTINGS({ commit }, params) {
    try {
      const data = await requestGetPaybackSettings(params);

      if (data?.result) {
        commit("SET_FUNERAL_LIST", data.result);

        return true;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  // 지원율 설정 저장
  async REQUEST_SAVE_FUNERAL_PAYBACK(_, params) {
    try {
      const data = await requestSaveFuneralPayback(params);

      if (data?.result) {
        return data.result;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  // 장례식장 상품 설정 불러오기
  async REQUEST_GET_FUNERAL_SHOP({ commit }, params) {
    try {
      const data = await requestGetFuneralShopList(params);

      if (data?.result) {
        commit("SET_FUNERAL_SHOP_LIST", data.result);

        return true;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  // 장례식장 상품 설정
  async REQUEST_SAVE_FUNERAL_SHOP(_, params) {
    try {
      const data = await requestSaveFuneralShop(params);

      if (data?.result) {
        return data.result;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  // 장례식장 설정
  async REQUEST_SAVE_FUNERAL_INFO(_, params) {
    try {
      const data = await requestSaveFuneralInfo(params);

      if (data?.result) {
        return data.result;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
  // 장례식장 커스텀 파일 업로드
  async REQUEST_FUNERAL_CUSTOM_FILE_UPLOAD(_, params) {
    try {
      const data = await requestFuneralCustomFileUpload(params);

      if (data?.result) {
        return data.result;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  // 커스텀 파일 삭제
  async REQUEST_FUNERAL_CUSTOM_FILE_DELETE(_, params) {
    try {
      const data = await requestFuneralCustomFileDelete(params);

      if (data?.result) {
        return data.result;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  // 빈소명 변경
  async REQUEST_UPDATE_FUNERAL_RASPBERRY(_, params) {
    try {
      const data = await requestUpdateFuneralRaspberry(params);

      if (data?.result) {
        return data.result;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  async REQUEST_DELETE_FUNERAL_RASPBERRY(_, params) {
    try {
      const data = await requestDeleteFuneralRaspberry(params);

      if (data?.result) {
        return data.result;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  async REQUEST_FUNERAL_RASP_FILE_UPLOAD(_, params) {
    try {
      const data = await requestFuneralRaspFileUpload(params);

      if (data?.result) {
        return data.result;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
  async REQUEST_FUNERAL_RASP_FILE_DELETE(_, params) {
    try {
      const data = await requestFuneralRaspFileDelete(params);

      if (data?.result) {
        return data.result;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
};

export default { mutations, state, actions, getters };
