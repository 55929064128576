const state = {
  loadingStatus: false,
};
const getters = {
  getLoadingStatus: (state) => state.loadingStatus,
};

const mutations = {
  START_LOADING(state) {
    state.loadingStatus = true;
  },
  END_LOADING(state) {
    setTimeout(() => {
      state.loadingStatus = false;
    }, 2000);
  },
};
const actions = {};

export default { mutations, state, actions, getters };
