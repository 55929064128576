import Vue from "vue";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.tz.setDefault("Asia/Seoul");

const emptyDate = () => {
  return "";
};

Vue.mixin({
  methods: {
    YYMMDDSLASHDate(val) {
      if (!val) {
        return emptyDate();
      }
      return dayjs(val).format("YYYY-MM-DD");
    },

    YYYYMMDDHHmmDate(val) {
      if (!val) {
        return emptyDate();
      }
      return dayjs(val).format("YYYY-MM-DD HH:mm");
    },

    KOREAYYYYMMDD(val) {
      if (!val) {
        return "";
      }
      return dayjs(val).format("YYYY년 MM월 DD일");
    },
    KOREAYYYYMMDDHHmm(val) {
      if (!val) {
        return "";
      }
      return dayjs(val).format("YYYY년 MM월 DD일 HH시 mm분");
    },

    KOREAYYYYMMDDEEHHMMDate(val) {
      if (!val) {
        return emptyDate();
      }

      const koreaE = ["(일)", "(월)", "(화)", "(수)", "(목)", "(금)", "(토)"];
      return (
        dayjs(val).format("YYYY년 MM월 DD일 ") +
        koreaE[dayjs(val).day()] +
        dayjs(val).format(" HH시 mm분")
      );
    },

    PRINTYYMMDDDate(val) {
      if (val === null || val === undefined || val === "") {
        return "";
      }
      return dayjs(val).format("YYYY년 MM월 DD일");
    },

    getTodayStart() {
      const today = dayjs().startOf("day");
      return this.YYMMDDSLASHDate(today);
    },

    getTodayEnd() {
      const today = dayjs().endOf("day");
      return this.YYMMDDSLASHDate(today);
    },

    getLastWeekStart() {
      const lastWeek = dayjs().subtract(7, "day").startOf("day");
      return this.YYMMDDSLASHDate(lastWeek);
    },

    getLastMonthStart() {
      const oneMonthAgo = dayjs().subtract(1, "month");
      return this.YYMMDDSLASHDate(oneMonthAgo);
    },

    setDateFilter(filterType) {
      let start, end;

      switch (filterType) {
        case "yesterday":
          start = dayjs().subtract(1, "day").startOf("day");
          end = dayjs().subtract(1, "day").endOf("day");
          break;
        case "today":
          start = dayjs().startOf("day");
          end = dayjs().endOf("day");
          break;
        case "last3days":
          start = dayjs().subtract(3, "day").startOf("day");
          end = dayjs().endOf("day");
          break;
        case "lastWeek":
          start = dayjs().subtract(7, "day").startOf("day");
          end = dayjs().endOf("day");
          break;
        case "lastMonth":
          start = dayjs().subtract(1, "month").startOf("month");
          end = dayjs().subtract(1, "month").endOf("month");
          break;
        case "thisMonth":
          start = dayjs().startOf("month");
          end = dayjs().endOf("month");
          break;
        default:
          return;
      }

      this.searchFilter.startDate = this.YYMMDDSLASHDate(start);
      this.searchFilter.endDate = this.YYMMDDSLASHDate(end);
    },
  },
});
