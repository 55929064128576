import {
  requestGetShopOrderList,
  requestShopOrderRegist,
  requestShopOrderCancel,
  requestShopOrderEvent,
  requestShopOrderConfirm,
  requestShopDeliConfirm,
  requestShopMemoUpdate,
} from "../apis/shopOrder_api";

const state = {
  shopOrderList: [],
  totalOrderCount: 0,
  totalPaidOrderCount: 0,
  totalCancelledOrderCount: 0,
  totalReadyOrderCount: 0,

  totalWaitedOrderCount: 0,
  totalFailedOrderCount: 0,

  totalConfirmCount: 0,
  totalWaitConfirmCount: 0,
  totalPages: 0,
  currentPage: 1,
  pageSize: 10,
};
const getters = {
  getShopOrderList: (state) => state.shopOrderList,
  getTotalOrderCount: (state) => state.totalOrderCount,
  getTotalPaidOrderCount: (state) => state.totalPaidOrderCount,
  getTotalCancelledOrderCount: (state) => state.totalCancelledOrderCount,
  getTotalReadyOrderCount: (state) => state.totalReadyOrderCount,
  getTotalWaitedOrderCount: (state) => state.totalWaitedOrderCount,
  getTotalFailedOrderCount: (state) => state.totalFailedOrderCount,

  getTotalConfirmCount: (state) => state.totalConfirmCount,
  getTotalWaitConfirmCount: (state) => state.totalWaitConfirmCount,

  getShopOrderTotalPages: (state) => state.totalPages,
  getShopOrderPageSize: (state) => state.pageSize,
};
const mutations = {
  SET_SHOP_ORDER_LIST(state, payload) {
    state.shopOrderList = payload.shopOrderList.map((order, index) => ({
      ...order,
      index,
    }));
    state.totalOrderCount = payload.totalOrderCount;
    state.totalPaidOrderCount = payload.totalPaidOrderCount;
    state.totalCancelledOrderCount = payload.totalCancelledOrderCount;
    state.totalWaitedOrderCount = payload.totalWaitedOrderCount;
    state.totalFailedOrderCount = payload.totalFailedOrderCount;
    state.totalReadyOrderCount = payload.totalReadyOrderCount;
    state.totalConfirmCount = payload.totalConfirmCount;
    state.totalWaitConfirmCount = payload.totalWaitConfirmCount;

    state.totalPages = payload.totalPages;
    state.pageSize = payload.pageSize;
  },
};
const actions = {
  async REQUEST_GET_SHOP_ORDER_LIST({ commit }, params) {
    try {
      const data = await requestGetShopOrderList(params);

      if (data?.result) {
        commit("SET_SHOP_ORDER_LIST", data.result);

        return true;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  async REQUEST_SHOP_ORDER_REGIST(_, params) {
    try {
      const result = await requestShopOrderRegist(params);
      if (result) {
        return result;
      }

      return false;
    } catch (e) {
      console.error(e);
      return false;
    }
  },

  async REQUEST_SHOP_ORDER_CANCEL(_, params) {
    try {
      const result = await requestShopOrderCancel(params);

      if (result) {
        return result;
      }

      return false;
    } catch (e) {
      console.error(e);
      return false;
    }
  },

  async REQUEST_SHOP_ORDER_EVENT(_, params) {
    try {
      const result = await requestShopOrderEvent(params);

      if (result) {
        return result;
      }

      return false;
    } catch (e) {
      console.error(e);
      return false;
    }
  },

  async REQUEST_SHOP_ORDER_CONFIRM(_, params) {
    try {
      const result = await requestShopOrderConfirm(params);

      if (result) {
        return result;
      }

      return false;
    } catch (e) {
      console.error(e);
      return false;
    }
  },

  async REQUEST_SHOP_DELI_CONFIRM(_, params) {
    try {
      const result = await requestShopDeliConfirm(params);

      if (result) {
        return result;
      }

      return false;
    } catch (e) {
      console.error(e);
      return false;
    }
  },

  async REQUEST_SHOP_MEMO_UPDATE(_, params) {
    try {
      const result = await requestShopMemoUpdate(params);

      if (result) {
        return result;
      }

      return false;
    } catch (e) {
      console.error(e);
      return false;
    }
  },
};

export default { mutations, state, actions, getters };
