import Vue from "vue";
import VueRouter from "vue-router";

import NotFound from "../components/NotFound.vue";

import ServiceRouter from "./service/router";
import ObituaryRouter from "./obituary/router";
import AdminRouter from "./admin/router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/secure",
    name: "Secure1",
    component: () => import("../views/secure/Secure1.vue"),
  },
  {
    path: "/secure2",
    name: "Secure2",
    component: () => import("../views/secure/Secure2.vue"),
  },

  ...ServiceRouter,
  ...ObituaryRouter,
  ...AdminRouter,
  {
    path: "*",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  base: process.env.BASE_URL,
});

export default router;
