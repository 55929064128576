import { requestSurNameList } from "@/apis/surName_api";

const state = {
  surNameList: [],
};

const getters = {
  getSurNameList(state) {
    return state.surNameList;
  },
};

const mutations = {
  SUR_NAME_LIST(state, data) {
    state.surNameList = data.surNameList;
  },
  SUR_NAME_INIT(state) {
    state.surNameList = [];
  },
};

const actions = {
  async REQUEST_SUR_NAME_LIST({ commit }, params) {
    try {
      const data = await requestSurNameList(params);

      if (data?.result) {
        commit("SUR_NAME_LIST", data.result);

        return true;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
};

export default { mutations, state, actions, getters };
