import {
  requestGetCityList,
  requestGetCityFuneralList,
  requestSaveCityDeliCost,
  requestSaveFuneralDeliCost,
} from "../apis/city_api";

const state = {
  cityList: [],
  sidoList: [],

  totalPages: 0,
  currentPage: 1,
  pageSize: 10,

  cityFuneralList: [],
};
const getters = {
  getCityList: (state) => state.cityList,
  getSidoList: (state) => state.sidoList,

  getCityTotalPages: (state) => state.totalPages,
  getCityPageSize: (state) => state.pageSize,

  getCityFuneralList: (state) => state.cityFuneralList,
};
const mutations = {
  SET_CITY_LIST(state, payload) {
    state.cityList = payload.cityList;
    state.sidoList = payload.sidoList;
    state.totalPages = payload.totalPages;
  },
  SET_CITY_FUNERAL_LIST(state, payload) {
    state.cityFuneralList = payload.cityFuneralList;
  },
};
const actions = {
  async REQUEST_GET_CITY_LIST({ commit }, params) {
    try {
      const data = await requestGetCityList(params);

      if (data?.result) {
        commit("SET_CITY_LIST", data.result);

        return true;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  async REQUEST_GET_CITY_FUNERAL_LIST({ commit }, params) {
    try {
      const data = await requestGetCityFuneralList(params);

      if (data?.result) {
        commit("SET_CITY_FUNERAL_LIST", data.result);

        return true;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  // 군/구 배송비 저장
  async REQUEST_SAVE_CITY_DELI_COST(_, params) {
    try {
      const result = await requestSaveCityDeliCost(params);

      if (result) {
        return result;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  // 특정 장례식장 배송비 저장
  async REQUEST_SAVE_FUNERAL_DELI_COST(_, params) {
    try {
      const result = await requestSaveFuneralDeliCost(params);

      if (result) {
        return result;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
};

export default { mutations, state, actions, getters };
