import axios from "axios";

const client = axios.create({
  baseURL: `${process.env.VUE_APP_APIURL}/admin`,
});

client.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("v4_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 관리자 정보 받아오기
const requestGetAdminInfo = async () => {
  return client
    .get(`/`)
    .then((res) => {
      const { data } = res;

      return data;
    })
    .catch((err) => {
      alert("관리자 조회를 실패하였습니다");
      console.error(err);
    });
};

// 관리자 로그인
const requestAdminLogin = async (params) => {
  return client
    .post(`/login`, params)
    .then((res) => {
      const { data } = res;

      if (data.code === "0000") {
        return data;
      }

      if (data.code === "E001") {
        alert("관리자 로그인을 실패하였습니다");
        return;
      }

      alert(data.message);
      return null;
    })
    .catch((err) => {
      alert("관리자 로그인을 실패하였습니다");
      console.error(err);
    });
};

// 관리자 등록
const requestAdminRegist = async (params) => {
  return client
    .post(`/regist`, params)
    .then((res) => {
      const { data } = res;

      return data;
    })
    .catch((err) => {
      alert("관리자 등록을 실패하였습니다");
      console.error(err);
    });
};

const requestGetObituaryList = async (params) => {
  return client
    .post(`/obituary-list`, params)
    .then((res) => {
      const { data } = res;

      return data;
    })
    .catch((err) => {
      alert("부고 조회에 실패하였습니다");
      console.error(err);
    });
};

const requestGetAdminUserList = async (params) => {
  return client
    .post(`/user-list`, params)
    .then((res) => {
      const { data } = res;

      return data;
    })
    .catch((err) => {
      alert("사용자 조회에 실패하였습니다");
      console.error(err);
    });
};

const requestRegistAdminUser = async (params) => {
  return client
    .post(`/user/regist`, params)
    .then((res) => {
      const { data } = res;

      if (data.code === "E001") {
        alert(data.message);
        return null;
      }
      return data;
    })
    .catch((err) => {
      alert("사용자 등록에 실패하였습니다");
      console.error(err);
    });
};

const requestDeleteAdminUser = async (params) => {
  return client
    .post(`/user/delete`, params)
    .then((res) => {
      const { data } = res;

      return data;
    })
    .catch((err) => {
      alert("사용자 삭제에 실패하였습니다");
      console.error(err);
    });
};

const requestResetAdminUserPwd = async (params) => {
  return client
    .post(`/user/reset-password`, params)
    .then((res) => {
      const { data } = res;

      return data;
    })
    .catch((err) => {
      alert("사용자 삭제에 실패하였습니다");
      console.error(err);
    });
};

export {
  requestGetAdminInfo,
  requestAdminLogin,
  requestAdminRegist,
  requestGetObituaryList,
  requestGetAdminUserList,
  requestRegistAdminUser,
  requestDeleteAdminUser,
  requestResetAdminUserPwd,
};
