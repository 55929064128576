import axios from "axios";

const client = axios.create({
  baseURL: `${process.env.VUE_APP_APIURL}/funeral`,
});

client.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("v4_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const requestGetFuneralList = async (params) => {
  return client
    .post(`/list`, params)
    .then((res) => {
      const { data } = res;
      if (data.code === "E001") {
        alert("장례식장 조회에 실패하였습니다");

        return;
      }
      return data;
    })
    .catch((err) => {
      alert("장례식장 조회에 실패하였습니다");
      console.error(err);
    });
};
const requestGetPaybackSettings = async (params) => {
  return client
    .get(`/payback-setting/list?FUNERAL_NO=${params.FUNERAL_NO}`)
    .then((res) => {
      const { data } = res;
      if (data.code === "E001") {
        alert("장례식장 페이백 설정 조회에 실패하였습니다");

        return;
      }
      return data;
    })
    .catch((err) => {
      alert("장례식장 페이백 설정 조회에 실패하였습니다");
      console.error(err);
    });
};

const requestSaveFuneralPayback = async (params) => {
  return client
    .post(`/save/payback`, params)
    .then((res) => {
      const { data } = res;
      if (data.code === "E001") {
        alert("페이백 설정에 실패하였습니다");
        return;
      }
      return data;
    })
    .catch((err) => {
      alert("페이백 설정에 실패하였습니다");
      console.error(err);
    });
};

const requestGetFuneralShopList = async ({ FUNERAL_NO }) => {
  return client
    .get(`/shop/list?FUNERAL_NO=${FUNERAL_NO}`)
    .then((res) => {
      const { data } = res;
      if (data.code === "E001") {
        alert("장례식장 상품 조회에 실패하였습니다");
        return;
      }

      return data;
    })
    .catch((err) => {
      alert("장례식장 상품 조회에 실패하였습니다");
      console.error(err);
    });
};

const requestSaveFuneralShop = async (params) => {
  return client
    .post(`/shop/save`, params)
    .then((res) => {
      const { data } = res;

      if (data.code === "E001") {
        alert("장례식장 상품 설정에 실패하였습니다");
        return;
      }

      return data;
    })
    .catch((err) => {
      alert("장례식장 상품 설정에 실패하였습니다");
      console.error(err);
    });
};

const requestSaveFuneralInfo = async (params) => {
  return client
    .post(`/save`, params)
    .then((res) => {
      const { data } = res;

      if (data.code === "E001") {
        alert("장례식장 설정에 실패하였습니다");
        return;
      }

      alert("장례식장 정보를 저장하였습니다");

      return data;
    })
    .catch((err) => {
      alert("장례식장 설정에 실패하였습니다");
      console.error(err);
    });
};

const requestFuneralCustomFileUpload = async (params) => {
  let form = new FormData();
  for (let i = 0; i < params.files.length; i++) {
    form.append("fileList", params.files[i]);
  }

  form.append("funeralNo", JSON.stringify(params.funeralNo));
  form.append("foldername", JSON.stringify(params.foldername));
  form.append("typeArray", JSON.stringify(params.typeArray));

  const token = localStorage.getItem("v4_token");
  return client
    .post(`/upload/custom-file`, form, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      const { data } = res;

      if (data.code === "E001") {
        alert("커스텀 파일 업로드에 실패하였습니다");
        return;
      }

      alert("파일 업로드를 완료 하였습니다");

      return data;
    })
    .catch((err) => {
      alert("커스텀 파일 업로드에 실패하였습니다");
      console.error(err);
    });
};

const requestFuneralCustomFileDelete = async (params) => {
  return client
    .post(`/delete/custom-file`, params)
    .then((res) => {
      const { data } = res;

      if (data.code === "E001") {
        alert("커스텀 파일 삭제에 실패하였습니다");
        return;
      }

      alert("해당 커스텀 파일을 삭제 하였습니다");

      return data;
    })
    .catch((err) => {
      alert("커스텀 파일 삭제에 실패하였습니다");
      console.error(err);
    });
};

const requestUpdateFuneralRaspberry = async (params) => {
  return client
    .post(`/update/raspberry`, params)
    .then((res) => {
      const { data } = res;

      if (data.code === "E001") {
        alert("빈소명 변경에 실패하였습니다");
        return;
      }

      alert("해당 빈소명을 변경합니다");

      return data;
    })
    .catch((err) => {
      alert("빈소명 변경에 실패하였습니다");
      console.error(err);
    });
};

const requestDeleteFuneralRaspberry = async (params) => {
  return client
    .post(`/delete/raspberry`, params)
    .then((res) => {
      const { data } = res;

      if (data.code === "E001") {
        alert("해당 빈소 비활성화에 실패하였습니다");
        return;
      }

      alert("해당 빈소를 비활성화 합니다");

      return data;
    })
    .catch((err) => {
      alert("해당 빈소 비활성화에 실패하였습니다");
      console.error(err);
    });
};

const requestFuneralRaspFileUpload = async (params) => {
  let form = new FormData();
  for (let i = 0; i < params.files.length; i++) {
    form.append("fileList", params.files[i]);
  }

  form.append("funeralNo", JSON.stringify(params.funeralNo));
  form.append(
    "RASPBERRY_CONNECTION_NO",
    JSON.stringify(params.RASPBERRY_CONNECTION_NO)
  );

  const token = localStorage.getItem("v4_token");
  return client
    .post(`/upload/raspberry-file`, form, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      const { data } = res;

      if (data.code === "E001") {
        alert("파일 업로드에 실패하였습니다");
        return;
      }

      alert("파일 업로드를 완료 하였습니다");

      return data;
    })
    .catch((err) => {
      alert("파일 업로드에 실패하였습니다");
      console.error(err);
    });
};

const requestFuneralRaspFileDelete = async (params) => {
  return client
    .post(`/delete/raspberry-file`, params)
    .then((res) => {
      const { data } = res;

      if (data.code === "E001") {
        alert("파일 삭제에 실패하였습니다");
        return;
      }

      alert("해당  파일을 삭제 하였습니다");

      return data;
    })
    .catch((err) => {
      alert("파일 삭제에 실패하였습니다");
      console.error(err);
    });
};
export {
  requestGetFuneralList,
  requestGetPaybackSettings,
  requestSaveFuneralPayback,
  requestGetFuneralShopList,
  requestSaveFuneralShop,
  requestSaveFuneralInfo,
  requestFuneralCustomFileUpload,
  requestFuneralCustomFileDelete,
  requestUpdateFuneralRaspberry,
  requestDeleteFuneralRaspberry,
  requestFuneralRaspFileUpload,
  requestFuneralRaspFileDelete,
};
