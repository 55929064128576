import store from "@/store"; // 경로는 실제 store 파일의 위치에 따라 조정해야 합니다.
import { requestGetAdminInfo } from "../../apis/admin_api";

async function requireAuth(to, from, next) {
  const token = localStorage.getItem("v4_token");

  if (!token) {
    next({ name: "Admin" }); // 로그인 페이지로 이동하는 로직. 로그인 페이지의 name이 'Login'인 경우.
    return;
  }
  // 유저 정보가 store에 없는 경우 API를 통해 불러온다.

  if (!store.state.admin.admin) {
    requestGetAdminInfo()
      .then((data) => {
        if (!data || data.code === "0001") {
          alert(data.message);
          next({ name: "Admin" });
          return;
        }

        store.commit("SET_ADMIN", data?.result);
        next(); // 유저 정보를 불러온 후 페이지로 이동
      })
      .catch((err) => {
        console.error(err);
        next({ name: "Admin" });
      });

    return;
  }
  next(); // 이미 유저 정보가 있으면 페이지로 바로 이동
}

async function autoLogin(to, from, next) {
  const token = localStorage.getItem("v4_token");

  if (!token) {
    next(); // 로그인 페이지로 이동하는 로직. 로그인 페이지의 name이 'Login'인 경우.
    return;
  }
  // 유저 정보가 store에 없는 경우 API를 통해 불러온다.

  if (!store.state.admin.admin) {
    requestGetAdminInfo()
      .then((data) => {
        if (!data || data.code === "0001") {
          alert(data.message);
          next();
          return;
        }

        store.commit("SET_ADMIN", data?.result);
        next({ name: "AdminObituaryList" }); // 유저 정보를 불러온 후 페이지로 이동
      })
      .catch((err) => {
        console.error(err);
        next();
      });

    return;
  }
  next(); // 이미 유저 정보가 있으면 페이지로 바로 이동
}

export default [
  {
    meta: {
      title: "Admin",
    },
    path: "/admin",
    name: "Admin",
    component: () => import("@/views/admin/Admin.vue"),
    beforeEnter: autoLogin,
  },
  {
    meta: {
      title: "AdminFuneralList",
    },
    path: "/admin/funeral-list",
    name: "AdminFuneralList",

    component: () => import("@/views/admin/AdminFuneralList.vue"),
    beforeEnter: requireAuth,
  },

  {
    meta: {
      title: "AdminObituaryList",
    },
    path: "/admin/obituary-list",
    name: "AdminObituaryList",
    component: () => import("@/views/admin/AdminObituaryList.vue"),
    beforeEnter: requireAuth,
  },

  {
    meta: {
      title: "AdminOrderList",
    },
    path: "/admin/order-list",
    name: "AdminOrderList",
    component: () => import("@/views/admin/AdminOrderList.vue"),
    beforeEnter: requireAuth,
  },

  {
    meta: {
      title: "AdminCalWreath",
    },
    path: "/admin/cal-wreath",
    name: "AdminCalWreath",
    component: () => import("@/views/admin/AdminCalWreath.vue"),
    beforeEnter: requireAuth,
  },

  {
    meta: {
      title: "AdminCalWreathDetail",
    },
    path: "/admin/cal-wreath-detail",
    name: "AdminCalWreathDetail",
    component: () => import("@/views/admin/AdminCalWreathDetail.vue"),
    beforeEnter: requireAuth,
  },

  // 도시 배송비 설정
  {
    meta: {
      title: "AdminCityList",
    },
    path: "/admin/city-list",
    name: "AdminCityList",
    component: () => import("@/views/admin/AdminCityList.vue"),
    beforeEnter: requireAuth,
  },

  {
    meta: {
      title: "AdminShopList",
    },
    path: "/admin/shop-list",
    name: "AdminShopList",
    component: () => import("@/views/admin/AdminShopList.vue"),
    beforeEnter: requireAuth,
  },

  {
    meta: {
      title: "AdminUserList",
    },
    path: "/admin/user-list",
    name: "AdminUserList",
    component: () => import("@/views/admin/AdminUserList.vue"),
    beforeEnter: requireAuth,
  },
];
