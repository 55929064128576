import axios from "axios";

const client = axios.create({
  baseURL: `${process.env.VUE_APP_APIURL}/funeral/cal`,
});

client.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("v4_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const requestGetFuneralCalList = async (params) => {
  return client
    .post(`/list`, params)
    .then((res) => {
      const { data } = res;
      if (data.code === "E001") {
        alert("정산 리스트 조회에 실패하였습니다");

        return;
      }
      return data;
    })
    .catch((err) => {
      alert("정산 리스트 조회에 실패하였습니다");
      console.error(err);
    });
};

const requestGetAllFuneralCalList = async (params) => {
  return client
    .post(`/all-list`, params)
    .then((res) => {
      const { data } = res;
      if (data.code === "E001") {
        alert("정산 리스트 조회에 실패하였습니다");

        return;
      }
      return data;
    })
    .catch((err) => {
      alert("정산 리스트 조회에 실패하였습니다");
      console.error(err);
    });
};

const requestGetFuneralCalDetail = async (params) => {
  return client
    .post(`/detail`, params)
    .then((res) => {
      const { data } = res;
      if (data.code === "E001") {
        alert("정산 리스트 조회에 실패하였습니다");

        return;
      }
      return data;
    })
    .catch((err) => {
      alert("정산 리스트 조회에 실패하였습니다");
      console.error(err);
    });
};

const requestGetAllFuneralCalDetail = async (params) => {
  return client
    .post(`/all-detail`, params)
    .then((res) => {
      const { data } = res;
      if (data.code === "E001") {
        alert("정산 리스트 조회에 실패하였습니다");

        return;
      }
      return data;
    })
    .catch((err) => {
      alert("정산 리스트 조회에 실패하였습니다");
      console.error(err);
    });
};

export {
  requestGetFuneralCalList,
  requestGetAllFuneralCalList,
  requestGetFuneralCalDetail,
  requestGetAllFuneralCalDetail,
};
